import { addDoc, arrayRemove, arrayUnion, deleteDoc, deleteField, doc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import * as analyticsService from "@/shared/core/analytics/hooks/useAnalyticsService";

import { db, fb } from "../../shared/infra/init";
import { User, WorkspaceInviteV2, WorkspaceMemberRole, WorkspaceMemberScope } from "../../types/db";

export const sendEmail = httpsCallable(fb.functions, "emailInvite");

export const deleteMember = async (workspaceId: string, userId: string) => {
	const workspaceDocument = doc(db.workspaces, workspaceId);
	updateDoc(workspaceDocument, {
		"users.allIds": arrayRemove(userId),
		[`users.byId.${userId}`]: deleteField(),
	});

	analyticsService.accountRemovedUser({ workspaceId });
};

export const reactivateMember = async (workspaceId: string, userId: string, role: WorkspaceMemberRole) => {
	const workspaceDocument = doc(db.workspaces, workspaceId);
	updateDoc(workspaceDocument, {
		"users.allIds": arrayUnion(userId),
		[`users.byId.${userId}`]: { role },
	});

	analyticsService.accountAddedUser({ role, workspaceId });
};

export const updateMemberRole = (workspaceId: string, userId: string, role: WorkspaceMemberRole) => {
	const workspaceDocument = doc(db.workspaces, workspaceId);
	// @ts-expect-error TS(2769) FIXME: No overload matches this call.
	updateDoc(workspaceDocument, { [`users.byId.${userId}.role`]: role });

	const user = doc(db.users, userId);
	// @ts-expect-error TS(2769) FIXME: No overload matches this call.
	updateDoc(user, { [`access.${workspaceId}.role`]: role });
};

export type CreateInvite = {
	entityId: string; //ID to the document that user is being invited to "boardId" | "workspaceId"
	entityTitle: string;
	email: string;
	role: WorkspaceMemberRole;
	user: User;
	workspaceId: string;
	type: WorkspaceMemberScope;
	message?: string;
};

export const buildInviteDocument = ({
	entityId: id,
	entityTitle: title,
	email,
	role,
	workspaceId,
	user: admin,
	type,
}: CreateInvite): WorkspaceInviteV2 => {
	const baseInvite = {
		email: email.toLowerCase(),
		rejectedAt: null,
		acceptedAt: null,
		version: 2 as const,
		admin: admin.username,
		adminEmail: admin.email,
		role: role as WorkspaceMemberRole,
		invitedAt: fb.timestamp(),
	};

	switch (type) {
		case "workspace":
			return {
				...baseInvite,
				type: type,
				workspace: id,
				workspaceTitle: title,
			};
		case "guest":
			return {
				...baseInvite,
				type: type,
				boardId: id,
				boardTitle: title,
				workspace: workspaceId,
			};

		default: {
			const _exhaustiveCheck: never = type;
			return _exhaustiveCheck;
		}
	}
};

export async function createInvite(args: CreateInvite) {
	const { entityId: id, entityTitle: title, email, role, user: admin, type, message } = args;

	const invite = buildInviteDocument(args);
	const { id: inviteId } = await addDoc(db.workspaceInvites, invite);

	analyticsService.inviteSent({ invite });

	sendEmail({
		email,
		name: title,
		id,
		type,
		inviteId: inviteId,
		role,
		authUser: admin,
		message,
	});

	return inviteId;
}

export const deleteInvite = (inviteId: string) => {
	const inviteDocument = doc(db.workspaceInvites, inviteId);
	deleteDoc(inviteDocument);
};
