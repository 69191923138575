import { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { useNavigationService } from "@/domains/accounts/components/sidebarNavigation/hooks/useNavigationService";
import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";
import { projectDelete } from "@/modules/board/api/projectDelete";
import { useAnalyticsService } from "@/shared/core/analytics/hooks/useAnalyticsService";

/**
 * Note this currently doesn't handle removing shared projects, this will error when trying to delete...
 */
export const useDeleteProject = (projectId: string | undefined) => {
	const navigate = useNavigate();
	const analyticsService = useAnalyticsService();
	const removeProject = useProjectsStore((state) => state.removeProject);

	const { getAllParents } = useNavigationService();

	return useCallback(() => {
		const deleteProject = async () => {
			if (!projectId) {
				return;
			}

			const [folder1, folder2] = getAllParents(projectId);

			if (!folder1) {
				return;
			}

			await projectDelete({
				parentId: folder1.id,
				favoriteId: folder2?.id,
				projectId,
				archive: false,
			});

			removeProject(projectId);

			analyticsService.projectDeleted({ boardId: projectId, workspaceId: folder1.workspaceId });
		};

		deleteProject();
		navigate("/w");
	}, [navigate, projectId, getAllParents, removeProject, analyticsService]);
};
