import styled from "styled-components";

import { useFilterService } from "@/modules/board/filter/hooks/useFilterService";

import { NodeMetaPersonValue } from "../../../../domains/nodes/components/details/meta/types/person/NodeMetaPersonValue";
import { Dropdown, DropdownContent, DropdownMenuItem, DropdownTrigger, Truncate } from "../../../../shared/system";
import { NodeMetaAllTypes, NodeMetaValueSelect } from "../../../../types/db";
import { Filter, FilterOptionNode, FilterTypes } from "../filterModel";
import { dateValues } from "../utils/dateValues";

/**
 * These values have been mutated in the selector 'getNodeMetaWithValuesHydrated' typing could be improved.
 * Additional comments on selector for possible resolution.
 **/

const useFilterValues = (meta: NodeMetaAllTypes | FilterOptionNode): { id: string; label: JSX.Element }[] => {
	switch (meta.type) {
		case "person": {
			// nodeMeta and  node.meta values have confused types
			const value = meta.value as unknown as string[];
			return (
				value?.map((id: string) => {
					return {
						id,
						label: <NodeMetaPersonValue userId={id} />,
					};
				}) || []
			);
		}
		case "date":
		case "dateRange": {
			return dateValues;
		}
		case "rootNode": {
			return [
				{
					id: meta.value.id,
					label: <Truncate>{meta.value.title}</Truncate>,
				},
			];
		}
		case "select":
		case "list": {
			return meta.value.map(({ id, title }: NodeMetaValueSelect) => {
				return {
					id: id,
					label: <div>{title}</div>,
				};
			});
		}
		case "link":
		case "text":
		case "email": {
			return [
				{
					id: meta.id,
					label: <div>{meta.label}</div>,
				},
			];
		}
		default: {
			const _exhaustiveCheck: never = meta;
			return _exhaustiveCheck;
		}
	}
};

type Props = {
	options: NodeMetaAllTypes | FilterOptionNode;
	filter: Filter;
};

export const FilterGroupValue = ({ options, filter }: Props) => {
	const { updateMetaFilter } = useFilterService();
	const dropdownOptions = useFilterValues(options);

	if (filter.type === FilterTypes.rootNode) {
		return (
			<Dropdown>
				<Trigger>{dropdownOptions[0].label}</Trigger>
			</Dropdown>
		);
	}

	const { value } = filter;
	const currentValue = dropdownOptions.find(({ id }) => id === value)?.label || "no values";
	const isDisabled = dropdownOptions.length < 2;

	return (
		<Dropdown>
			<Trigger data-testid="filter-group-value" disabled={isDisabled}>
				{currentValue}
			</Trigger>
			<DropdownContent>
				{dropdownOptions.map(({ id, label }) => {
					const handleSelect = () => {
						updateMetaFilter({ ...filter, value: id });
					};

					return (
						<DropdownMenuItem onSelect={handleSelect} data-testid={`filter-group-value[${id}]`} key={id}>
							{label}
						</DropdownMenuItem>
					);
				})}
			</DropdownContent>
		</Dropdown>
	);
};

const Trigger = styled(DropdownTrigger)`
	max-width: 200px;
`;
