import { SyntheticEvent, useCallback, useEffect, useState } from "react";

import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { DetailsIcon } from "@/domains/nodes/components/details/components/DetailsIcon";
import { NodeMeta } from "@/domains/nodes/components/details/meta/NodeMeta";
import { KeyResultsList } from "@/domains/nodes/components/keyResults/KeyResultsList";
import { useEventsService } from "@/domains/nodes/hooks/useEventsService";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { ChildNodes } from "@/modules/note/childNodes/ChildNodes";
import { Divider, ResizableTextarea } from "@/shared/system";

import { useDetailsSectionVisibility } from "../../board/hooks/useDetailsSectionVisibility";
import { usePermissions } from "../../workspace/hooks/usePermissions";
import { CoverImageAdd } from "../coverImage/CoverImageAdd";
import { getNoteId } from "../hooks/useGetNote";
import { updateNote } from "../noteFunctions";
import Notepad from "../Notepad";
import { NoteDetailsActivity } from "./NoteDetailsActivity";

type Props = {
	node: NodeModel;
};

export const NoteDetails: React.FC<Props> = ({ node }) => {
	const [title, setTitle] = useState(node.title);
	const noteId = useRecoilValue(getNoteId(node.id));
	const { canEdit } = usePermissions("project");
	const { displayTitle, displayMeta, displayResults, displayActivity, displayChildren } = useDetailsSectionVisibility();
	const { update } = useNodesService();
	const { addEvent } = useEventsService();

	const nodeId = node.id;
	const boardId = node.board;
	const nodeTitle = node.title;

	useEffect(() => {
		setTitle(nodeTitle);
	}, [nodeTitle]);

	const handleBodyChange = useCallback(
		(body: any, oldDoc: any) => {
			updateNote({
				nodeId,
				noteId,
				body,
			});

			addEvent({
				type: "node-details",
				action: "update",
				refId: nodeId,
				url: `https://poda.io/b/${boardId}/${nodeId}`,
				newData: { doc: body },
				oldData: { doc: oldDoc },
			});
		},
		[boardId, addEvent, nodeId, noteId],
	);

	const handleTitleChange = useCallback((e: SyntheticEvent<HTMLTextAreaElement>) => {
		setTitle(e.currentTarget.value);
	}, []);

	const handleSaveTitle = useCallback(
		(e: SyntheticEvent<HTMLTextAreaElement>) => {
			const newTitle = e.currentTarget.value;

			if (newTitle !== node.title) {
				update(node.id, { title: newTitle });
			}
		},
		[node.id, node.title, update],
	);

	return (
		<>
			{displayTitle && (
				<>
					<DetailsIcon node={node} />
					{/* <ParentNodeIndicator nodeId={node.id} boardId={board.id} /> */}
					<NodeTitle>
						<CoverImageAdd node={node} />
						<ResizableTextarea
							disabled={!canEdit}
							aria-label="node title"
							autoFocus={title ? false : true}
							value={title}
							tabIndex={1}
							onChange={handleTitleChange}
							onBlur={handleSaveTitle}
							placeholder="Untitled"
							data-testid="nodeSidebarTitle"
							styledProps={{
								isPlain: true,
								noHover: true,
								mt: "16px",
								mb: 0,
								p: 0,
							}}
						/>
					</NodeTitle>
					<Divider my="24px" />
				</>
			)}
			{displayMeta && (
				<>
					<NodeMeta node={node} />
					<Divider my="24px" />
				</>
			)}
			<div>
				{displayResults && <KeyResultsList />}
				<Notepad nodeId={nodeId} onSave={handleBodyChange} />
				{displayChildren && <ChildNodes node={node} />}
				{displayActivity && <NoteDetailsActivity node={node} />}
			</div>
		</>
	);
};

const NodeTitle = styled.div`
	textarea {
		font-weight: 700;
		font-size: 32px;
		line-height: 41px;
		color: var(--color-text-heading);
	}
	:hover {
		div {
			visibility: visible;
		}
	}
`;
